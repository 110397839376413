(function(){
    function handle(container) {
        Array.prototype.forEach.call(container.querySelectorAll('a[data-href][data-toggle="modal"][data-target]'), function(anchor) {
            anchor.removeEventListener('click', onOpen, true);
            anchor.addEventListener('click', onOpen, true);
        });
    }

    function request(anchor, formData, modalBody) {
        const method = formData === null ? 'GET': 'POST';
        var params = {
            method: method,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
            body: formData,
            credentials: 'same-origin'
        };
        fetch(anchor.dataset.href, params)
            .then(function(response){
                if (response.redirected) {
                    window.location.reload();
                    return null;
                }

                if (response.headers.has('x-data')) {
                    const data = JSON.parse(response.headers.get('x-data'));
                    const event = new CustomEvent('modal.submitted', {bubbles: true, detail: data});
                    anchor.dispatchEvent(event);
                    $(modalBody.closest('.modal')).modal('hide');

                    return null;
                }

                if (response.status !== 200) {
                    return null;
                }

                return response.text();
            })
            .then(function(html){
                if (html === null) {
                    return;
                }
                modalBody.innerHTML = html;
                load(anchor, modalBody);

                $(modalBody.closest('.modal')).modal('show');
            });
    }

    function onOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        var modal = document.querySelector(event.currentTarget.dataset.target);
        var modalBody = modal.querySelector('.modal-body');
        if (modalBody) {
            request(event.currentTarget, null, modalBody);
        }

    }

    function load(anchor, modalBody) {
        Array.prototype.forEach.call(modalBody.getElementsByTagName("script"), function (script) {
            var _script = document.createElement('script');
            _script.type = 'text/javascript';
            _script.innerText = script.innerText;

            document.head.appendChild(_script);
        });

        modalBody.dispatchEvent(new CustomEvent('init', {bubbles: true}));

        var form = modalBody.querySelector('form');

        if (form) {
            form.action = anchor.dataset.href;
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                var formData = new FormData(this);
                request(anchor, formData, modalBody);
            });
        }
    }

    window.addEventListener('load', function () {
        handle(document);
    });

    window.handleModals = handle;

    $('.modal').on('hide.bs.modal', function(e) {
        if (e.target.querySelector('form') && !confirm('Are you sure you want to close the form ?')) {
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    });
})();

