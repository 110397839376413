(function () {
    const mbBigFile = 1000000;

    let loadingForms = document.querySelectorAll('form[data-form-load-wrap]');

    loadingForms.forEach(function (form) {
        let loadingWrap = document.querySelector(form.dataset.formLoadWrap);
        let fileThreshold = parseBoolean(form.dataset.fileThreshold, true);
        let percentageWrap = loadingWrap.querySelector('#form-loading-percentage');
        let loadingTextWrap = loadingWrap.querySelector('#form-loading-text');
        let loadingImg = loadingWrap.querySelector('img');
        let percentageWrapParent = percentageWrap.parentElement;

        loadingWrap.style.display = 'none';
        loadingWrap.style.transition = 'opacity 1s ease-in-out';

        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();

            let hasBigFile = isFormWithBigFile(form);

            if (fileThreshold === true) {
                if (false === hasBigFile) {
                    return form.submit();
                }
            }

            let xhr = new XMLHttpRequest();

            form.style.display = 'none';
            loadingWrap.style.display = 'block';
            loadingWrap.style.opacity = '1';
            if (hasBigFile === false) {
                percentageWrapParent.innerText = 'Loading...'
            }
            xhr.open(form.method, form.action, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    let percentage = Math.round((event.loaded / event.total) * 100);

                    if (hasBigFile) {
                        if (percentageWrap) {
                            percentageWrap.innerText = percentage;
                        }
                        if (hasBigFile && loadingTextWrap) {
                            loadingTextWrap.innerText = formatBytes(event.loaded) + ' of ' + formatBytes(event.total) + ' uploaded';
                        }
                    }
                }
            }   ;

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 500) {
                        alert('Error');
                    } else if (xhr.status === 200) {
                        let data = JSON.parse(this.responseText);

                        if (data.hasOwnProperty('redirect_url')) {
                            if (loadingImg.dataset.imgEnd) {
                                loadingImg.src = loadingImg.dataset.imgEnd;
                                loadingTextWrap.innerText = 'Success!';
                                percentageWrapParent.style.display = 'none';
                            }

                            window.location.replace(data.redirect_url);
                            loadingWrap.style.opacity = '0';
                        } else {
                            form.submit();
                        }
                    }
                }
            };

            return xhr.send(new FormData(form));
        });
    });

    // Only if a file to upload
    function isFormWithBigFile(form) {
        for (let i = 0; i < form.elements.length; i++) {
            if ('file' === form.elements[i].type) {
                for (let u = 0; u < form.elements[i].files.length; u++) {
                    // Minimum 1mb
                    if (form.elements[i].files[u].size > mbBigFile){
                        return true;
                    }
                }
            }
        }

        return false;
    }

    function formatBytes(bytes) {
        if (bytes < 1024) {
            return bytes + ' b';
        } else if (bytes < 1048576) {
            return (bytes / 1024) + ' KB';
        } else if (bytes < 1073741824) {
            return (bytes / 1048576).toFixed(2) + ' MB';
        }

        return (bytes / 1073741824).toFixed(2) + ' GB';
    }

    function parseBoolean(string, defaultValue = false) {
        if (string !== undefined && typeof string === 'string') {
            string = string.toLowerCase();

            if (string === 'true') {
                return true;
            }

            if (string === 'false') {
                return false;
            }
        }

        return defaultValue;
    }
})();
