(function () {
    function onFetch(event) {
        event.preventDefault();

        var anchor = event.target;
        fetch(anchor.dataset.fetch, {credentials: 'same-origin'})
            .then(function (response) {
                if (response.redirected) {
                    window.location.reload();
                    return;
                }
                anchor.dispatchEvent(new CustomEvent('fetched', {detail: response, bubbles: true}));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    Array.prototype.forEach.call(document.querySelectorAll('[data-fetch]'), function (anchor) {
        var eventName = anchor.dataset.fetchEvent ? anchor.dataset.fetchEvent : 'click';
        anchor.addEventListener(eventName, onFetch, true);

        if (anchor.dataset.autoload) {
            anchor.dispatchEvent(new CustomEvent(eventName));
        }
    });
})();